<template>
  <div
    v-if="server !== null"
    class="server"
  >
    <span
      v-if="server.toLowerCase() !== demoServer"
      class="tag"
    > ⚠️️ {{ server }} </span>
    <span
      v-else
      class="tag demo"
    > {{ server }} </span>
  </div>
</template>

<script>
import devServers from '@/dev-servers.json'

export default {
  computed: {
    demoServer() {
      return 'demo'.toLowerCase()
    },
    server() {
      const TAG = '$$$'
      const e = Object.entries(devServers)
        .filter(r => (this.$store.getters['server/getServer'] + TAG).indexOf(r[1].trim() + TAG) !== -1)
      return e.length === 1 ? e[0][0] : null
    },
  },
}
</script>

<style scoped lang="scss">
@use "sass:map";
@import '../../../@core/scss/base/bootstrap-extended/_include';
@import '../../../@core/scss/base/components/_include';

.server {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 22px;

  &::before {
    display: block;
    content: '';
    min-height: 24px;
    width: 100%;
    height: calc(env(safe-area-inset-top) + 15px);
  }

  span {
    position: relative;
    z-index: 1000;
    color: $light;
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.22);
    padding: 10px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 1px;
    text-transform: uppercase;

    & {
      background-color: lighten($danger, 5);
    }

    &.demo {
      background-color: $warning;
    }
  }
}
</style>
